import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PageNotFound from '@/views/PageNotFound'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/products/:product_id',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductView')
  },
  {
    path: '/categories/:category_id',
    name: 'category',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoryView')
  },
  {
    path: '/subcategories/:subcategory_id',
    name: 'subcategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubcategoryView')
  },
  {
    path: '/vendors/:vendor_id',
    name: 'vendor',
    component: () => import(/* webpackChunkName: "about" */ '../views/VendorView')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "about" */ '../views/CartView')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView'),
  },
  {
    path: '/become-vendor',
    name: 'new-vendor',
    component: () => import(/* webpackChunkName: "about" */ '../views/BecomeVendor'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountView'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') === null) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
  },
  {
    path: '/success-order',
    name: 'success order',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccesssOrder'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') === null) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs'),
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqComponent'),
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
